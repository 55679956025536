export const appBuildAssetsDir = "/static/_nuxt/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"innerHTML":"var _tmr=window._tmr||(window._tmr=[]);_tmr.push({id:\"3578826\",type:\"pageView\",start:(new Date()).getTime()});(function(d,w,id){if(d.getElementById(id))return;var ts=d.createElement(\"script\");ts.type=\"text/javascript\";ts.async=true;ts.id=id;ts.src=\"https://top-fwz1.mail.ru/js/code.js\";var f=function(){var s=d.getElementsByTagName(\"script\")[0];s.parentNode.insertBefore(ts,s)};if(w.opera==\"[object Opera]\"){d.addEventListener(\"DOMContentLoaded\",f,false)}else{f()}})(document,window,\"tmr-code\");","type":"text/javascript"},{"innerHTML":"(function(a,n,t,i,f,li,c,k,e,r){a[t]=a[t]||function(){(a[t].a=a[t].a||[]).push(arguments)};n.classList.add(i);c=function(){n.classList.remove(i)};li.callback=c;a[t].antiFlicker=li;setTimeout(c,f)})(window,document.documentElement,'ymab','varioqub-antiflicker',4000,{'metrika.88769417':true});(function(e,x,pe,r,i,me,nt){e[i]=e[i]||function(){(e[i].a=e[i].a||[]).push(arguments)},me=x.createElement(pe),me.async=1,me.src=r,nt=x.getElementsByTagName(pe)[0],nt.parentNode.insertBefore(me,nt)})(window,document,'script','https://abt.s3.yandex.net/expjs/latest/exp.js','ymab');ymab('metrika.88769417','init');","type":"text/javascript","async":true}],"noscript":[{"children":"<img src=\"https://top-fwz1.mail.ru/counter?id=3578826;js=na\" style=\"position:absolute;left:-9999px;\" alt=\"Top.Mail.Ru\" />"}],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false